import UniversalImage from "library/UniversalImage"
import { fresponsive } from "library/fullyResponsive"
import { useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import type { LogoPart } from "./Layout"

export default function TransitionLogo({
	logoImages,
	keyVal,
}: {
	logoImages: LogoPart[]
	keyVal: number
}) {
	const wrapperRef = useRef<HTMLDivElement>(null)

	return (
		<Wrapper ref={wrapperRef}>
			<LoaderLogoSVG
				width="52"
				height="52"
				viewBox="0 0 52 52"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="26"
					cy="26"
					r="25"
					fill="black"
					stroke="#C4C4C4"
					strokeWidth="2"
				/>
			</LoaderLogoSVG>

			<LoaderPath1 key={keyVal} image={logoImages[0]} alt="logo portion" />

			<LoaderPath2
				key={keyVal + 1}
				className="loader-path-2"
				image={logoImages[1]}
				alt="logo portion"
			/>
		</Wrapper>
	)
}

const loaderPath2Animation = keyframes`
	0% {
		offset-distance: 90%;
	}
	10% {
		offset-distance: 90%;
	}
	33% {
		z-index: 2;
	}
	50% {
		z-index: 0;
	}
	60% {
		offset-distance: 190%;
	}
	100% {
    offset-distance: 190%;
  }
`
const loaderPath1Animation = keyframes`
   0% {
    offset-distance: -52%;
  }
  10% {
		offset-distance: -52%;
	}
  60% {
    offset-distance: -152%;
  }
  100% {
    offset-distance: -152%;
	
  }

`

const Wrapper = styled.div`
	position: relative;

	${fresponsive(css`
		width: 100px;
		height: 100px;
	`)};
`

const LoaderPath1 = styled(UniversalImage)`
	position: absolute;
	z-index: 1;
	will-change: transform;
	${fresponsive(css`
		width: 21px;
		height: auto;
	`)}
	offset-path: ellipse(10.5% 2%);
	offset-rotate: 0deg;
	animation: ${loaderPath1Animation} 2s ease-in-out infinite;
`

const LoaderPath2 = styled(UniversalImage)`
	position: absolute;
	z-index: 0;
	will-change: transform;
	${fresponsive(css`
		width: 23px;
		height: auto;
	`)}
	offset-path: ellipse(19% 9%);
	offset-rotate: 0deg;
	animation: ${loaderPath2Animation} 2s ease-in-out infinite;
`

const LoaderLogoSVG = styled.svg`
	position: relative;
	display: grid;
	width: 100%;
	height: 100%;
`
