import Footer from "components/Footer"
import { graphql, useStaticQuery } from "gatsby"
import type { IGatsbyImageData } from "gatsby-plugin-image"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { useTrackPageReady } from "library/pageReady"
import useTrackFrameTime from "library/useTrackFrameTime"
import styled, { createGlobalStyle, css } from "styled-components"
import colors, { ColorStyle } from "styles/colors"
import CookieConsent from "./CookieConsent"
import Header from "./Header"
import Preloader from "./Preloader"
import Transition from "./Transition"

interface LayoutProps {
	children: React.ReactNode
}

export type LogoPart = {
	childImageSharp: {
		readonly gatsbyImageData: IGatsbyImageData
	} | null
} | null

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()
	useTrackFrameTime()

	const routing: Queries.DynamicRoutingQuery = useStaticQuery(graphql`
		query DynamicRouting {
			solutions: allContentfulTemplatedPageSolutions(
				filter: { id: { nin: ["8d4b1430-7016-5cd9-88ca-35053ffaa8ba"] } }
			) {
				nodes {
					id
					slug
					pageTitle
					icon
					previewText
				}
			}
			partners: allContentfulTemplatedPagePartner(
				filter: { id: { nin: ["97ca1ce2-cedb-58f3-9cd0-807244a27179"] } }
			) {
				nodes {
					id
					slug
					pageTitle
					icon
					previewText
				}
			}
			platform: allContentfulTemplatedPagePlatformDetail(
				filter: {
					id: {
						nin: [
							"41c30637-889c-5631-b233-69a8a01d1032"
							"7d79d97e-9801-5ca4-a467-e64e66f3a000"
							"ff95c34e-ce85-5423-9efa-28cc5195021d"
						]
					}
				}
			) {
				nodes {
					id
					slug
					pageTitle
					icon
					previewText
					orderInNavigation
				}
			}
			leftLogoPath: file(relativePath: { eq: "global/leftLogoPath.png" }) {
				childImageSharp {
					gatsbyImageData(placeholder: NONE)
				}
			}
			rightLogoPath: file(relativePath: { eq: "global/rightLogoPath.png" }) {
				childImageSharp {
					gatsbyImageData(placeholder: NONE)
				}
			}
		}
	`)

	const { leftLogoPath, rightLogoPath } = routing

	const logoParts: LogoPart[] = [leftLogoPath, rightLogoPath]

	const sortedRouting: typeof routing = {
		...routing,
		platform: {
			nodes: [...routing.platform.nodes].sort((a, b) => {
				if (a.orderInNavigation === b.orderInNavigation) return 0
				if (a.orderInNavigation === null) return 1
				if (b.orderInNavigation === null) return -1
				return a.orderInNavigation - b.orderInNavigation
			}),
		},
	}

	return (
		<>
			<CookieConsent />
			<GlobalStyle />
			<ColorStyle />
			<Preloader logoImages={logoParts} />
			<Transition logoImages={logoParts} />
			<Header dynamicInfo={sortedRouting} />
			<Scroll>
				<Main>{children}</Main>
				<Footer dynamicInfo={sortedRouting} />
			</Scroll>
		</>
	)
}

const Main = styled.main`
	overflow-x: clip;
`

const globalCss = css`
	/* default text styles */
	html {
		/* if your project uses a dark color for most text, set that here */
		color: ${colors.blackMain};
		background: ${colors.backgroundBlack};
	}

	#smooth-content {
		background: ${colors.backgroundBlack};
	}

	* {
		/* need this so that fonts match figma */
		text-rendering: geometricprecision;
		-webkit-font-smoothing: antialiased;
	}

	/* dev utilities */
	/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
	html:has(gatsby-qod) {
		* {
			font-family: cursive;
		}
	}

	/** restore default focus states for elements that need them */
	*:focus-visible {
		outline: 2px solid #00f8;
	}
`

const GlobalStyle = createGlobalStyle`${globalCss}`
