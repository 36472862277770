import { createStyleString } from "@capsizecss/core"
import fullyResponsive from "library/fullyResponsive"
import { css } from "styled-components"

export const clampText = (lines: number) => css`
	overflow: hidden;
	text-overflow: ellipsis;
	/* stylelint-disable-next-line property-no-vendor-prefix  */
	-webkit-text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${lines};
`

export const transparentText = css`
	/* stylelint-disable-next-line property-no-vendor-prefix  */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	background-size: 100%;
	background-clip: text;
`

const dentonRegularMetrics = {
	capHeight: 720,
	ascent: 850,
	descent: -250,
	lineGap: 225,
	unitsPerEm: 1000,
}

/**
 * in the case of matter, it seems the metrics are the same regardless of font weight
 * you should verify this if you add more font weights/styles to typography.css
 */

const matterRegularMetrics = {
	capHeight: 700,
	ascent: 904,
	descent: -190,
	lineGap: 20,
	unitsPerEm: 1000,
}

const matterSquareMonoMetrics = {
	capHeight: 700,
	ascent: 900,
	descent: -300,
	lineGap: 0,
	unitsPerEm: 1000,
}

const makeStyleString = (options: Parameters<typeof createStyleString>[1]) =>
	createStyleString("&", options).replaceAll(".&", "&")

const textStyles = {
	h1: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 108,
			leading: 108 * 0.88,
			fontMetrics: matterRegularMetrics,
		})};
		font-weight: 500;
		font-style: normal;
		letter-spacing: -5.4px;
	`),
	h2: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 96,
			leading: 96 * 0.88,
			fontMetrics: matterRegularMetrics,
		})};
		font-weight: 500;
		letter-spacing: -4.8px;
	`),
	h3: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 72,
			leading: 72 * 0.92,
			fontMetrics: matterRegularMetrics,
		})};
		font-weight: 500;
		font-style: normal;
		letter-spacing: -2.88px;
	`),
	h4: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 60,
			leading: 60 * 0.92,
			fontMetrics: matterRegularMetrics,
		})};
		font-weight: 500;
		font-style: normal;
		letter-spacing: -2.4px;
	`),
	h5: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 48,
			leading: 48 * 0.92,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: -1.92px;
	`),
	h6: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 36,
			leading: 36 * 0.92,
			fontMetrics: matterRegularMetrics,
		})};
		font-weight: 500;
		font-style: normal;
		letter-spacing: -1.44px;
	`),
	mobileHeader: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 26,
			leading: 26 * 1.1,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.4px;
	`),
	oversized: fullyResponsive(css`
		font-family: Denton, sans-serif;
		${makeStyleString({
			fontSize: 128,
			leading: 128,
			fontMetrics: dentonRegularMetrics,
		})};
		font-weight: 400;
		letter-spacing: -1.28px;
	`),
	kicker1: fullyResponsive(css`
		font-family: "Matter SQ Mono", monospace;
		${makeStyleString({
			fontSize: 15,
			leading: 15,
			fontMetrics: matterSquareMonoMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: 3.9px;
		text-transform: uppercase;
	`),
	kicker2: fullyResponsive(css`
		font-family: "Matter SQ Mono", monospace;
		${makeStyleString({
			fontSize: 12,
			leading: 12,
			fontMetrics: matterSquareMonoMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: 3.12px;
		text-transform: uppercase;
	`),
	kicker3: fullyResponsive(css`
		font-family: "Matter SQ Mono", monospace;
		${makeStyleString({
			fontSize: 10,
			leading: 10,
			fontMetrics: matterSquareMonoMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: 2.6px;
		text-transform: uppercase;
	`),
	sub1: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 20,
			leading: 20 * 1.28,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.4px;
	`),
	sub2: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 16,
			leading: 16 * 1.28,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.32px;
	`),
	sub3: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 13,
			leading: 13 * 1.28,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.24px;
	`),

	body1L: fullyResponsive(css`
		font-family: Denton, serif;
		${makeStyleString({
			fontSize: 18,
			leading: 18 * 1.4,
			fontMetrics: dentonRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.72px;
	`),
	body1R: fullyResponsive(css`
		font-family: Denton, serif;
		${makeStyleString({
			fontSize: 15,
			leading: 15 * 1.4,
			fontMetrics: dentonRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.6px;
	`),
	body1S: fullyResponsive(css`
		font-family: Denton, serif;
		${makeStyleString({
			fontSize: 12,
			leading: 12 * 1.5,
			fontMetrics: dentonRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.6px;
	`),
	body2L: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 18,
			leading: 18 * 1.4,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
	`),
	body2RBold: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 15,
			leading: 15 * 1.4,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 500;
		letter-spacing: 0.15px;
	`),
	body2R: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 15,
			leading: 15 * 1.4,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.15px;
	`),
	body2S: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 12,
			leading: 12 * 1.4,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.24px;
	`),
	body2Mini: fullyResponsive(css`
		font-family: Matter, sans-serif;
		${makeStyleString({
			fontSize: 9,
			leading: 9 * 1.4,
			fontMetrics: matterRegularMetrics,
		})};
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.18px;
	`),
	custom: {
		endpointOverview: fullyResponsive(css`
			font-family: Matter, sans-serif;
			${makeStyleString({
				fontSize: 48,
				leading: 48 * 1.32,
				fontMetrics: matterRegularMetrics,
			})};
			font-style: normal;
			letter-spacing: -1.92px;
			font-weight: 500;
		`),
		endpointNumber: fullyResponsive(css`
			font-family: "Matter SQ Mono", monospace;
			font-size: 140px;
			font-style: normal;
			font-weight: 500;
			text-transform: uppercase;
		`),
		endpointPercent: fullyResponsive(css`
			font-family: "Matter SQ Mono", monospace;
			${makeStyleString({
				fontSize: 14,
				leading: 14,
				fontMetrics: matterSquareMonoMetrics,
			})};
			font-style: normal;
			font-weight: 500;
			letter-spacing: 0.84px;
			text-transform: uppercase;
		`),
	},
}

export default textStyles
