import { ReactComponent as MDR } from "images/global/icons/all/247MDR.svg"
import { ReactComponent as Blog } from "images/global/icons/all/Blog.svg"
import { ReactComponent as Careers } from "images/global/icons/all/Careers.svg"
import { ReactComponent as Company } from "images/global/icons/all/Company.svg"
import { ReactComponent as Compliance } from "images/global/icons/all/Compliance.svg"
import { ReactComponent as Detection } from "images/global/icons/all/Detection.svg"
import { ReactComponent as Email } from "images/global/icons/all/Email.svg"
import { ReactComponent as Endpoint } from "images/global/icons/all/Endpoint.svg"
import { ReactComponent as Identity } from "images/global/icons/all/Identity.svg"
import { ReactComponent as IncidentResponse } from "images/global/icons/all/IncidentResponse.svg"
import { ReactComponent as Industries } from "images/global/icons/all/Industries.svg"
import { ReactComponent as Insurability } from "images/global/icons/all/Insurability.svg"
import { ReactComponent as Insurance } from "images/global/icons/all/Insurance.svg"
import { ReactComponent as Monitoring } from "images/global/icons/all/Monitoring.svg"
import { ReactComponent as Resources } from "images/global/icons/all/Resources.svg"
import { ReactComponent as Security } from "images/global/icons/all/Security.svg"
import { ReactComponent as Training } from "images/global/icons/all/Training.svg"
import { ReactComponent as UseCases } from "images/global/icons/all/UseCases.svg"
import type { ComponentProps } from "react"

const ICON_MAP = {
	MDR: MDR,
	Blog: Blog,
	Careers: Careers,
	Company: Company,
	Compliance: Compliance,
	Detection: Detection,
	Email: Email,
	Endpoint: Endpoint,
	Identity: Identity,
	IncidentResponse: IncidentResponse,
	Industries: Industries,
	Insurability: Insurability,
	Insurance: Insurance,
	Monitoring: Monitoring,
	Resources: Resources,
	Security: Security,
	Training: Training,
	UseCases: UseCases,
}

export const isIconName = (x: string | null): x is keyof typeof ICON_MAP =>
	x ? x in ICON_MAP : false

export type IconName = keyof typeof ICON_MAP

export function Icon({
	name,
	...props
}: { name: IconName } & ComponentProps<"svg">) {
	const Icon = ICON_MAP[name]
	return <Icon {...props} />
}
