import gsap from "gsap"
import UniversalImage from "library/UniversalImage"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import styled, { css, keyframes } from "styled-components"
import type { LogoPart } from "./Layout"

export default function LoaderLogo({
	logoImages,
	passedRef,
}: {
	logoImages: LogoPart[]
	passedRef: HTMLDivElement | null
}) {
	useAnimation(
		() => {
			const tl = gsap.timeline()
			tl.to(passedRef, {
				opacity: 1,
				scale: 1,
				duration: 0.4,
				ease: "power2.inOut",
			})
		},
		[passedRef],
		{ scope: { current: passedRef } },
	)

	if (!logoImages || logoImages.length < 2) {
		return null
	}
	return (
		<Wrapper>
			<LoaderLogoSVG
				width="52"
				height="52"
				viewBox="0 0 52 52"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="26"
					cy="26"
					r="25"
					fill="black"
					stroke="#C4C4C4"
					strokeWidth="2"
				/>
			</LoaderLogoSVG>
			<LoaderPath1 image={logoImages[0]} alt="logo portion" />
			<LoaderPath2 image={logoImages[1]} alt="logo portion" />
		</Wrapper>
	)
}

const loaderPath2Animation = keyframes`
	0% {
		offset-distance: 90%;
	}
	33% {
		z-index: 2;
	}
	50% {
		z-index: 0;
	}
	60% {
		offset-distance: 190%;
	}
	100% {
    offset-distance: 190%;
  }
`
const loaderPath1Animation = keyframes`
   0% {
    offset-distance: -52%;
  }
  60% {
    offset-distance: -152%;
  }
  100% {
    offset-distance: -152%;
	
  }

`

const Wrapper = styled.div`
	position: relative;

	${fresponsive(css`
		width: 102px;
		height: 102px;
	`)};
`

const LoaderPath1 = styled(UniversalImage)`
	position: absolute;
	z-index: 1;
	${fresponsive(css`
		width: 24px;
		height: 30px;
	`)}
	offset-path: ellipse(11% 2%);
	offset-rotate: 0deg;
	animation: ${loaderPath1Animation} 3s ease-in-out infinite;
`

const LoaderPath2 = styled(UniversalImage)`
	position: absolute;
	z-index: 0;
	${fresponsive(css`
		width: 24px;
		height: 13px;
	`)}
	offset-path: ellipse(22% 9%);
	offset-rotate: 0deg;
	animation: ${loaderPath2Animation} 3s ease-in-out infinite;
`

const LoaderLogoSVG = styled.svg`
	position: relative;
	display: grid;
	width: 100%;
	height: 100%;
`
