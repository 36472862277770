import { useCookieState } from "ahooks"
import gsap from "gsap"
import { loader } from "library/Loader"
import { isBrowser } from "library/deviceDetection"
import { fresponsive } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import { useEffect, useRef } from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import { PrimaryButton } from "./Buttons/Button"
import { LinkText } from "./Buttons/LinkText"
import IconWidget from "./IconWidget"

declare global {
	interface Window {
		dataLayer: unknown[]
	}
}

if (isBrowser) window.dataLayer = window.dataLayer || []
function gtag(...args: unknown[]) {
	// biome-ignore lint/style/noArguments: gtag moment
	if (isBrowser) window.dataLayer.push(arguments)
}

function updateConsent(state: "granted" | "denied") {
	gtag("consent", "update", {
		ad_user_data: state,
		ad_personalization: state,
		ad_storage: state,
		analytics_storage: state,
	})

	// send cookie_consent_update event as well to fire any tags that need consent
	gtag("event", "cookie_consent_update")
}

export default function CookieConsent() {
	const wrapper = useRef<HTMLDivElement>(null)
	const [consent, setConsent] = useCookieState("we-got-cookie-consent")

	useEffect(() => {
		if (consent === "granted") updateConsent("granted")
		else if (consent === "denied") updateConsent("denied")

		if (consent)
			gsap.to(wrapper.current, {
				autoAlpha: 0,
			})
	}, [consent])

	loader.useEventListener("end", () => {
		if (!consent)
			gsap.to(wrapper.current, {
				y: 0,
				autoAlpha: 1,
				duration: 1,
				delay: 2,
				ease: "power2.out",
			})
	})

	return (
		<Wrapper ref={wrapper}>
			<GlobalStyle />
			{consent ? null : <HideOverlay />}
			<IconWidget name="Compliance" />
			<Title>Cookie Consent</Title>
			<Description>
				Rotate uses cookies to ensure you get the best experience. Your privacy
				is important to us and your content is never shared with 3rd party
				providers.
			</Description>
			<Row>
				<PrimaryButton
					color="orange"
					type="button"
					onClick={() => setConsent("granted")}
				>
					Allow All
				</PrimaryButton>
				<LinkText
					type="button"
					onClick={() => setConsent("denied")}
					large={useMedia(false, false, true, false)}
				>
					Disable All
				</LinkText>
			</Row>
		</Wrapper>
	)
}

const GlobalStyle = createGlobalStyle`
	.widget-align-right {
		transition: opacity 0.5s ease;
	}
`

const HideOverlay = createGlobalStyle`
	.widget-align-right {
		opacity: 0;
		visibility: hidden;
	}
`

const Wrapper = styled.div`
	${fresponsive(css`
		position: fixed;
		bottom: 24px;
		right: 24px;
		width: 328px;
		max-width: calc(100vw - 48px);
		border-radius: 20px;
		padding: 16px;
		background: ${colors.blackLight};
		z-index: 100;
		visibility: hidden;
		translate: 0 50%;
	`)}
`

const Title = styled.h1`
	${textStyles.sub1}
	color: ${colors.blueWhite};
	${fresponsive(css`
		margin-top: 16px;
	`)}
`

const Description = styled.p`
	${textStyles.body2S};
	color: ${colors.gray300};

	${fresponsive(css`
		margin-top: 12px;
		margin-bottom: 16px;
	`)}
`

const Row = styled.div`
	${fresponsive(css`
		display: flex;
		gap: 12px;
		align-items: center;
	`)}
`
