const internal = {
	home: "/",
	platformOverview: "/platform-overview",
	identityHub: "/identity-hub",
	emailHub: "/email-hub",
	endpointHub: "/endpoint-hub",
	trainingHub: "/training-hub",
	monitoringHub: "/monitoring-hub",
	detectionAndResponse: "/detection-and-response",
	securityPartners: "/security-partners",
	insurancePartners: "/insurance-partners",
	becomeAPartner: "/become-a-partner",
	insurance: "/insurance",
	compliance: "/compliance",
	incidentResponse: "/incident-response",
	mdr: "/mdr",
	careers: "/careers",
	resourceCenter: "/resource-center",
	contactUs: "/contact-us",
	bookDemo: "/book-demo",
	privacyPolicy: "/privacy-policy",
	termsOfUse: "/terms-of-use",
	easmHub: "/easm-hub",
} as const

const ourExternalLinks = {
	login: "https://app.withrotate.com/",
	about: "https://www.withrotate.com/about",
	blog: "https://www.withrotate.com/blog",
	linkedIn: "https://www.linkedin.com/company/withrotate/",
} as const

const thirdPartyLinks = {} as const

const links = {
	...internal,
	...ourExternalLinks,
	...thirdPartyLinks,
} as const

export default links

export const formIds = {
	demo: "227f0881-eccc-4f3d-bd7f-01b612537ed8",
	contact: "adf28005-f76c-48f1-bf4f-3adc6574177b",
	partners: "3af18909-7f5c-452b-88f9-c2253ec9f459",
	email: "10f1743a-33c1-43a5-a422-4d69508af2f4",
}
