exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-become-a-partner-tsx": () => import("./../../../src/pages/become-a-partner.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-tsx" */),
  "component---src-pages-book-demo-tsx": () => import("./../../../src/pages/book-demo.tsx" /* webpackChunkName: "component---src-pages-book-demo-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-contentful-templated-page-partner-slug-tsx": () => import("./../../../src/pages/{contentfulTemplatedPagePartner.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-templated-page-partner-slug-tsx" */),
  "component---src-pages-contentful-templated-page-platform-detail-slug-tsx": () => import("./../../../src/pages/{contentfulTemplatedPagePlatformDetail.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-templated-page-platform-detail-slug-tsx" */),
  "component---src-pages-contentful-templated-page-solutions-slug-tsx": () => import("./../../../src/pages/{contentfulTemplatedPageSolutions.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-templated-page-solutions-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-overview-tsx": () => import("./../../../src/pages/platform-overview.tsx" /* webpackChunkName: "component---src-pages-platform-overview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

