import styled, { css } from "styled-components"

import gsap from "gsap"
import { generateGradientBorder } from "library/generateGradientBorder"
import { useEffect, useRef } from "react"

export const useGradientBorderWrapper = (
	elementRef: React.RefObject<HTMLElement>,
	brightness = 100,
) => {
	const duration = 0.3
	const ease = "power4.out"

	useEffect(() => {
		const mouseEnter = () => {
			gsap.to(elementRef.current, {
				duration,
				ease,
				"--gradient": `linear-gradient(
					359deg,
					rgba(255 255 255 / 0.${brightness}) 0%,
					rgba(255 255 255 / 0) 50%
				)`,
			})
		}

		const mouseLeave = () => {
			gsap.to(elementRef.current, {
				duration,
				ease,
				"--gradient": `linear-gradient(
					180deg,
					rgba(255 255 255 / 0.${brightness}) 0%,
					rgba(255 255 255 / 0%) 50%
				)`,
			})
		}

		mouseLeave()

		const element = elementRef.current
		if (!element) return

		element.addEventListener("mouseenter", mouseEnter)
		element.addEventListener("mouseleave", mouseLeave)

		return () => {
			element.removeEventListener("mouseenter", mouseEnter)
			element.removeEventListener("mouseleave", mouseLeave)
		}
	}, [elementRef, brightness])
}

export const gradientBorderStyle = css`
	border-radius: 99vw;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		pointer-events: none;
		inset: 0;
		z-index: 1;
		border-radius: inherit;
		${generateGradientBorder("var(--gradient)", 1)}
	}
`

/**
 * creates a gradient border with clipping
 */
export function GradientBorderWrapper({
	children,
	brightness = 100,
	borderRadius,
}: {
	children: React.ReactNode
	brightness?: number
	borderRadius?: string
}) {
	const background = useRef<HTMLDivElement | null>(null)
	useGradientBorderWrapper(background, brightness)

	return (
		<Wrapper style={{ borderRadius: borderRadius }} ref={background}>
			{children}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: fit-content;
	height: fit-content;
	${gradientBorderStyle};
	border-radius: 99vw;
	overflow: clip;
`
