import * as Popover from "@radix-ui/react-popover"
import { PrimaryButton } from "components/Buttons/Button"
import { LinkText } from "components/Buttons/LinkText"
import DropdownOverview from "components/DropdownOverview"
import type { IconName } from "components/Icon"
import Subpage from "components/Subpage"
import DropdownBottomL from "images/global/dropdownBottomL.png"
import DropdownBottomM from "images/global/dropdownBottomM.png"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import { loadPage } from "library/Loader/TransitionUtils"
import UniversalLink from "library/Loader/UniversalLink"
import { useScrollLock } from "library/Scroll"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import getMedia from "library/getMedia"
import useAnimation from "library/useAnimation"
import { useBetterThrottle } from "library/useBetterThrottle"
import useMedia from "library/useMedia"
import { getResponsivePixels } from "library/viewportUtils"
import libraryConfig from "libraryConfig"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"
import type { CompanyData } from "."
import { LoginWrapper } from "./LoginWrapper"

const duration = 300

interface Data {
	title: string
	previewText: string
	iconName: string
	link: string
}

type SubpageQueryData =
	| Queries.DynamicRoutingQuery["platform"]
	| Queries.DynamicRoutingQuery["partners"]
	| Queries.DynamicRoutingQuery["solutions"]

export default function TabletMobileDropdown({
	scopeRef,
	dynamicInfo,
	companyData,
}: {
	scopeRef: React.RefObject<HTMLDivElement>
	dynamicInfo: Queries.DynamicRoutingQuery
	companyData: CompanyData[]
}) {
	const [rawOpen, setOpen] = useState(false)
	const open = useBetterThrottle(rawOpen, duration)
	const lineRef1 = useRef<SVGLineElement | null>(null)
	const lineRef2 = useRef<SVGLineElement | null>(null)
	const lineRef3 = useRef<SVGLineElement | null>(null)
	const expandedRef = useRef<HTMLDivElement | null>(null)
	const openButtons = useRef<HTMLDivElement | null>(null)
	const bottomMenuRef = useRef<HTMLDivElement | null>(null)
	const [isOverviewHovered, setIsOverviewHovered] = useState(false)
	const bottomImage = useMedia(
		DropdownBottomL,
		DropdownBottomL,
		DropdownBottomL,
		DropdownBottomM,
	)

	const { platform, partners, solutions } = dynamicInfo

	const timeline = useAnimation(
		() => {
			const tl = gsap.timeline({
				paused: true,
				duration: 0.3,
			})

			tl.fromTo(
				lineRef1.current,
				{
					attr: {
						x1: 0,
						x2: 36,
						y1: 0.5,
						y2: 0.5,
					},
				},
				{
					attr: {
						x1: 4,
						x2: 32,
						y1: -8,
						y2: 17,
					},
				},
				0,
			)

			tl.fromTo(
				lineRef2.current,
				{
					attr: {
						x1: 0,
						x2: 36,
						y1: 4.5,
						y2: 4.5,
					},
				},
				{
					attr: {
						x1: 4,
						x2: 32,
						y1: -8,
						y2: 17,
					},
				},
				0,
			)

			tl.fromTo(
				lineRef3.current,
				{
					attr: {
						x1: 0,
						x2: 36,
						y1: 8.5,
						y2: 8.5,
					},
				},
				{
					attr: {
						x1: 4,
						x2: 32,
						y1: 17,
						y2: -8,
					},
				},
				0,
			)
			tl.fromTo(
				expandedRef.current,
				{
					width: getResponsivePixels(getMedia(244, 244, 244, 350)),
				},
				{
					width: getResponsivePixels(getMedia(556, 556, 534, 350)),
					ease: "power2.inOut",
				},
				0,
			)
				.fromTo(
					bottomMenuRef.current,
					{
						autoAlpha: 0,
					},
					{
						autoAlpha: 1,
						ease: "power2.inOut",
					},
					0,
				)
				.fromTo(
					".login-wrapper",
					{
						width: getResponsivePixels(getMedia(244, 244, 244, 350)),
					},
					{
						width: getResponsivePixels(getMedia(244, 244, 534, 350)),
						ease: "power2.inOut",
					},
					0,
				)
				.fromTo(
					openButtons.current,
					{
						width: getResponsivePixels(0),
						opacity: 0,
					},
					{
						width: getResponsivePixels(getMedia(220, 220, 220, 180)),
						opacity: 1,
						ease: "power2.inOut",
					},
					0,
				)
				.fromTo(
					".see-rotate-responsive-nav",
					{
						opacity: 1,
						autoAlpha: 1,
						pointerEvents: "auto",
					},
					{
						opacity: 0,
						autoAlpha: 0,
						pointerEvents: "none",
						duration: 0.1,
					},
					0,
				)
				.fromTo(
					".mobile-logo",
					{
						opacity: 1,
						display: "inline-block",
						pointerEvents: "auto",
					},
					{
						opacity: 0,
						pointerEvents: "none",
						display: "none",
						duration: 0.1,
					},
					0,
				)

			return tl
		},
		[],
		{ recreateOnResize: true },
	)

	useEffect(() => {
		if (!timeline) return

		if (open) {
			timeline.play()
		} else {
			timeline.reverse()
		}
	}, [timeline, open])

	const handleClick = (link: string | null) => {
		if (!link) return
		setOpen(false)
		loadPage(link, libraryConfig.defaultTransition)
	}

	const createSubPages = (data: SubpageQueryData) => {
		return data.nodes.map((node) => {
			const { id, pageTitle, previewText, icon, slug } = node
			const iconToUse = icon?.[0] as IconName

			return (
				<Subpage
					onClick={() => handleClick(slug)}
					key={id}
					title={pageTitle ?? ""}
					previewText={previewText ?? ""}
					iconName={iconToUse ?? "Email"}
					link={slug ?? ""}
				/>
			)
		})
	}

	const companySubPages = companyData.map((node) => {
		const { id, pageTitle, previewText, icon, slug } = node

		return (
			<Subpage
				onClick={() => handleClick(slug)}
				key={id}
				title={pageTitle ?? ""}
				previewText={previewText ?? ""}
				iconName={icon as IconName}
				link={slug ?? ""}
			/>
		)
	})

	useScrollLock("lock", open)

	return (
		<PopoverRoot onOpenChange={setOpen}>
			<ExpandedMenu ref={expandedRef}>
				<LoginWrapper>
					<LogoWrapper className="mobile-logo" to={links.home}>
						<LogoSVG />
					</LogoWrapper>
					<Children>
						<OpenButtons ref={openButtons}>
							<InnerDiv>
								<LinkText to={links.login} large>
									Login
								</LinkText>
								<PrimaryButton to={links.contactUs} color="orange">
									See Rotate
								</PrimaryButton>
							</InnerDiv>
						</OpenButtons>
						<MenuButton>
							<Lines
								viewBox="0 0 36 9"
								overflow="visible"
								width="100%"
								height="100%"
								preserveAspectRatio="none"
							>
								<Line
									ref={lineRef1}
									x1={0}
									x2={36}
									y1={0.5}
									y2={0.5}
									preserveAspectRatio="none"
								/>
								<Line
									ref={lineRef2}
									x1={0}
									x2={36}
									y1={4.5}
									y2={4.5}
									preserveAspectRatio="none"
								/>
								<Line
									ref={lineRef3}
									x1={0}
									x2={36}
									y1={8.5}
									y2={8.5}
									preserveAspectRatio="none"
								/>
							</Lines>
						</MenuButton>
					</Children>
				</LoginWrapper>
				<BottomMenu ref={bottomMenuRef}>
					<InnerBottom>
						{platform.nodes.length > 0 && <MenuKicker>Platform</MenuKicker>}
						<DropdownOverview
							onClick={() => handleClick(links.platformOverview)}
							onMouseEnter={() => setIsOverviewHovered(true)}
							onMouseLeave={() => setIsOverviewHovered(false)}
						/>
						{platform.nodes.length > 0 && (
							<SubMenu>{createSubPages(platform)}</SubMenu>
						)}
						{solutions.nodes.length > 0 && <MenuKicker>Solutions</MenuKicker>}
						{solutions.nodes.length > 0 && (
							<SubMenu>{createSubPages(solutions)}</SubMenu>
						)}
						{partners.nodes.length > 0 && <MenuKicker>Partners</MenuKicker>}
						{partners.nodes.length > 0 && (
							<SubMenu>{createSubPages(partners)}</SubMenu>
						)}
						{companyData.length > 0 && <MenuKicker>Company</MenuKicker>}
						{companySubPages.length > 0 && <SubMenu>{companySubPages}</SubMenu>}
						<DropdownBottom src={bottomImage} alt="Dropdown" />
					</InnerBottom>
				</BottomMenu>
			</ExpandedMenu>

			<PrimaryButtonTablet
				className="see-rotate-responsive-nav"
				to={links.bookDemo}
				color="orange"
			>
				See Rotate
			</PrimaryButtonTablet>
		</PopoverRoot>
	)
}

const MenuButton = styled(Popover.Trigger)`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	-webkit-tap-highlight-color: transparent;

	${fresponsive(css`
		height: 9px;
		width: 36px;
	`)};
`

const Lines = styled.svg`
	${fresponsive(css`
		width: 100%;
		height: 100%;
	`)}
`

const Line = styled.line`
	stroke: ${colors.blueWhite};
	stroke-width: 1px;
	stroke-linecap: round;
`

const PopoverRoot = styled(Popover.Root)`
	width: 100%;
	position: relative;
`

const ExpandedMenu = styled.div`
	position: relative;
	transform-origin: left;
`

const OpenButtons = styled.div`
	position: relative;
	width: 0;
	overflow: clip;
	${fresponsive(css`
		height: 40px;
	`)};

	${ftablet(css`
		height: 47px;
	`)};
`

const InnerDiv = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	object-position: center center;
	align-items: center;

	${fresponsive(css`
		position: relative;
		width: 180px;
		gap: 10px;
		height: 40px;
	`)};

	${ftablet(css`
		width: 220px;
		height: 47px;
	`)};
`

const Children = styled.div`
	display: flex;
	gap: 40px;
	align-items: center;
	justify-content: space-between;

	${fmobile(css`
		width: 100%;
	`)};
`

const LogoWrapper = styled(UniversalLink)`
	position: relative;
	font-family: none;
	display: none;

	${fmobile(css`
		display: inline-block;
		width: 96px;
	`)}
`

const MenuKicker = styled.div`
	${textStyles.kicker3};
	color: ${colors.gray500};

	${fresponsive(css`
		margin: 40px 0 16px 16px;
	`)}

	&:first-of-type {
		margin-bottom: 8px;
	}
`

const BottomMenu = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background: black;
	max-height: 80dvh;
	overflow-y: auto;
	${fresponsive(css`
		top: 120%;
		width: 534px;
		padding: 0 16px;
		border-radius: 38px;
		background: ${colors.blackMain};
		box-shadow: 24px 28px 60px 8px rgba(0 0 0 / 35%),
			0 1px 1px 0 rgba(255 255 255 / 22%) inset;
	`)}

	${fmobile(css`
		width: 351px;
		padding: 0 8px;
		border-radius: 16px;
	`)}
`

const InnerBottom = styled.div`
	position: relative;

	${fresponsive(css`
		display: flex;
		flex-direction: column;
	`)}
`

const SubMenu = styled.div`
	display: grid;
	position: relative;

	${fresponsive(css`
		grid-auto-rows: 80px;
		grid-template-columns: repeat(2, 262px);
		border-radius: 8px;
		border: 0.5px solid ${colors.blackLight};
		border-top: none;
		overflow: clip;
	`)}

	${fmobile(css`
		grid-auto-rows: 80px;
		grid-template-columns: repeat(2, 1fr);
	`)}
`

const DropdownBottom = styled.img`
	${fresponsive(css`
		width: 100%;
		padding: 16px;
		height: auto;
		margin-bottom: 80px;
	`)}

	${ftablet(css`
		margin-bottom: 0;
	`)}

	${fmobile(css`
		margin-bottom: 0;
		width: 351px;
	`)}
`

const PrimaryButtonTablet = styled(PrimaryButton)`
	position: absolute;

	${fresponsive(css`
		right: 10px;
		height: 50px;
	`)}

	${fmobile(css`
		display: none;
	`)}
`
