import RadarImage from "images/global/radar.png"
import RadarHoverImage from "images/global/radarHover.png"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function DropdownOverview({
	onMouseEnter,
	onMouseLeave,
	onClick,
}: {
	onMouseEnter: () => void
	onMouseLeave: () => void
	onClick?: () => void
}) {
	return (
		<Wrapper
			type="button"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<ImageWrapper>
				<Image src={RadarImage} alt="Radar" />
				<HoveredImage src={RadarHoverImage} alt="Radar" />
			</ImageWrapper>
			<Content>
				<Title>Platform Overview</Title>
				<PreviewText>
					Understand Rotate’s top cybersecurity protection
				</PreviewText>
			</Content>
		</Wrapper>
	)
}

const ImageWrapper = styled.div`
	position: relative;

	${ftablet(css`
		width: 280px;
		height: 173px;
	`)}

	${fmobile(css`
		width: 109px;
		height: 72.5px;
		border: 0.25px solid #252525;
	`)}
`

const Image = styled.img`
	position: relative;

	${fresponsive(css`
		width: 218px;
		height: 145px;
	`)}

	${ftablet(css`
		width: 280px;
		height: 173px;
	`)}

	${fmobile(css`
		width: 109px;
		height: 72.5px;
	`)}
`

const HoveredImage = styled.img`
	position: absolute;
	opacity: 0;

	${fresponsive(css`
		top: 0;
		left: 0;
	`)}

	${ftablet(css`
		width: 280px;
		height: 173px;
	`)}

	${fmobile(css`
		width: 109px;
		height: 72.5px;
	`)}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 12px;
	`)}

	${ftablet(css`
		width: 218px;
		height: 100%;
		padding-bottom: 12px;
	`)}

	${fmobile(css`
		width: 210px;
		padding: 0;
	`)}
`

const Title = styled.h1`
	${textStyles.body2S};
	color: ${colors.gray300};

	${ftablet(css`
		${textStyles.body2L};
	`)}
`

const PreviewText = styled.p`
	${textStyles.body2Mini};
	color: ${colors.gray700};

	${ftablet(css`
		${textStyles.body2R};
	`)}
`

const Wrapper = styled(UniversalLink)`
	display: flex;
	align-items: center;
	flex-direction: column;
	border-right: 0.5px solid ${colors.blackLight};
	border-bottom: 0.5px solid ${colors.blackLight};
	transition: background-color 0.3s ease;

	/* Remove outline for non-keyboard :focus */
	*:focus:not(:focus-visible) {
		outline: none;
	}

	/* Optional: Customize :focus-visible */
	&:focus-visible {
		outline-color: transparent;
	}

	${fresponsive(css`
		border-top-left-radius: 15px;
		gap: 16px;
		padding: 16px;
		width: 250px;
	`)}

	${ftablet(css`
		padding: 16px 16px 16px 0;
		flex-direction: row;
		border-radius: 16px 16px 0 0;
		border: none;
		width: 100%;
		align-items: flex-end;
	`)}

	${fmobile(css`
		flex-direction: row;
		border: none;
		width: 100%;
		align-items: flex-end;
		padding: 16px 0;
	`)}

  &:hover {
		background-color: ${colors.orangeGradient08};
		cursor: pointer;

		${Image} {
			content: url(${RadarHoverImage});
		}

		${Title} {
			color: ${colors.orange500};
			transition: all 0.3s ease;
		}

		${PreviewText} {
			color: ${colors.gray600};
			transition: all 0.3s ease;
		}

		${Image} {
			opacity: 0;
		}

		${HoveredImage} {
			opacity: 1;
		}
	}
`
