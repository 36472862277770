import * as Popover from "@radix-ui/react-popover"
import { LinkText } from "components/Buttons/LinkText"
import DropdownOverview from "components/DropdownOverview"
import type { IconName } from "components/Icon"
import Subpage from "components/Subpage"
import { ReactComponent as CutoutSVG } from "images/global/cutout.svg"
import DropdownBottomL from "images/global/dropdownBottomL.png"
import DropdownBottomM from "images/global/dropdownBottomM.png"
import DropdownBottomS from "images/global/dropdownBottomS.png"
import { loadPage } from "library/Loader/TransitionUtils"
import { useScrollLock } from "library/Scroll"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { useBetterThrottle } from "library/useBetterThrottle"
import libraryConfig from "libraryConfig"
import { useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import type { DynamicRoutingQuery } from "types/alias"
import links from "utils/links"
import type { CompanyData } from "."

const duration = 300

type DynamicLinkType =
	| DynamicRoutingQuery["partners"]["nodes"]
	| DynamicRoutingQuery["solutions"]["nodes"]
	| DynamicRoutingQuery["platform"]["nodes"]
	| CompanyData[]

type DropdownProps = {
	children: string
	hasOverview?: boolean
	links: DynamicLinkType
}

export default function Dropdown({
	children,
	hasOverview,
	links: ddLinks,
}: DropdownProps) {
	const [rawOpen, setOpen] = useState(false)
	const open = useBetterThrottle(rawOpen, duration)
	const [numCols, setNumCols] = useState(1)
	const [firstColumnData, setFirstColumnData] = useState<DynamicLinkType>([])
	const [secondColumnData, setSecondColumnData] = useState<DynamicLinkType>([])
	const [isOverviewHovered, setIsOverviewHovered] = useState(false)
	const [isSubpage1Hovered, setIsSubpage1Hovered] = useState(false)
	useScrollLock("lock", open)

	useEffect(() => {
		if (hasOverview) {
			if (ddLinks.length >= 4) {
				setNumCols(2)
			}
		} else {
			if (ddLinks.length >= 6) {
				setNumCols(2)
			}
		}

		if (numCols === 2) {
			const [dataFirstHalf, dataSecondHalf] = splitData(ddLinks, numCols)
			setFirstColumnData(dataFirstHalf ?? [])
			setSecondColumnData(dataSecondHalf ?? [])
		} else {
			setFirstColumnData(ddLinks)
		}
	}, [hasOverview, numCols, ddLinks])

	const DropdownImage = hasOverview
		? numCols === 1
			? DropdownBottomM
			: DropdownBottomL
		: numCols === 1
			? DropdownBottomS
			: DropdownBottomM

	const imageWidth = numCols * 250 + (hasOverview ? 250 : 0)

	const splitData = (links: DynamicLinkType, numCols: number) => {
		if (numCols === 1) return [links]
		const mid = Math.ceil(links.length / 2)
		return [links.slice(0, mid), links.slice(mid)]
	}

	const handleClick = (link: string | null) => {
		if (!link) return
		setOpen(false)
		loadPage(link, libraryConfig.defaultTransition)
	}

	return (
		<div onMouseLeave={() => setOpen(false)} onMouseEnter={() => setOpen(true)}>
			<Popover.Root open={open} onOpenChange={setOpen}>
				<Popover.Anchor>
					<Popover.Trigger asChild>
						<DropdownLink $open={open} icon="chevron" to="">
							{children}
						</DropdownLink>
					</Popover.Trigger>
				</Popover.Anchor>
				<Content
					onOpenAutoFocus={(e) => e.preventDefault()}
					align="start"
					style={{
						pointerEvents: open ? "auto" : "none",
					}}
				>
					<Wrapper>
						<CutoutContainer>
							<StyledCutout
								isHovered={isOverviewHovered || isSubpage1Hovered}
							/>
						</CutoutContainer>
						<Inner>
							<MainContent>
								{hasOverview && (
									<DropdownOverview
										onClick={() => handleClick(links.platformOverview)}
										onMouseEnter={() => setIsOverviewHovered(true)}
										onMouseLeave={() => setIsOverviewHovered(false)}
									/>
								)}
								<Columns numCols={numCols}>
									<Column>
										{firstColumnData.length > 0 &&
											firstColumnData.map((item, index) => {
												const { id, slug, pageTitle, previewText, icon } = item
												return (
													<Subpage
														key={id}
														style={{
															borderTopLeftRadius:
																index === 0 && !hasOverview ? "15px" : "0",
															borderTopRightRadius:
																index === 0 && numCols === 1 ? "15px" : "0",
															borderRight:
																numCols === 2
																	? `0.5px solid ${colors.blackLight}`
																	: "none",
														}}
														onMouseEnter={() =>
															index === 0 && !hasOverview
																? setIsSubpage1Hovered(true)
																: null
														}
														onMouseLeave={() =>
															index === 0 && !hasOverview
																? setIsSubpage1Hovered(false)
																: null
														}
														onClick={() => {
															if (!slug) return
															loadPage(slug, libraryConfig.defaultTransition)
														}}
														title={pageTitle ?? ""}
														previewText={previewText ?? ""}
														iconName={icon as IconName}
														link={slug ?? ""}
													/>
												)
											})}
									</Column>
									{numCols === 2 && (
										<Column>
											{secondColumnData.length > 0 &&
												secondColumnData.map((item, index) => {
													const { id, slug, pageTitle, previewText, icon } =
														item

													return (
														<Subpage
															key={id}
															style={{
																borderTopRightRadius:
																	index === 0 ? "15px" : "0",
															}}
															title={pageTitle ?? ""}
															previewText={previewText ?? ""}
															iconName={icon as IconName}
															link={slug ?? ""}
															onClick={() => {
																if (!slug) return
																loadPage(slug, libraryConfig.defaultTransition)
															}}
														/>
													)
												})}
										</Column>
									)}
								</Columns>
							</MainContent>

							<DropdownBottom
								style={{ width: `${imageWidth}px` }}
								src={DropdownImage}
								alt="Dropdown"
							/>
						</Inner>
					</Wrapper>
				</Content>
			</Popover.Root>
		</div>
	)
}

const StyledCutout = styled(CutoutSVG)<{ isHovered: boolean }>`
	.cutout-top {
		fill: ${({ isHovered }) =>
			isHovered ? colors.orangeGradient08 : "transparent"};
	}

	.cutout-bottom {
		box-shadow: 0 1px 1px 0 rgba(255 255 255 / 50%) inset;
		stroke: ${colors.gray700};
	}

	transition: fill 0.35s ${eases.quad.inOut};

	${fresponsive(css`
		width: 100%;
		height: 100%;
	`)}
`

const slideIn = keyframes`
    from {
        transform: translateY(25%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`

const slideOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(25%);
        opacity: 0;
    }
`

const Content = styled(Popover.Content)`
	position: absolute;

	&:focus {
		outline: none;
	}

	&[data-state="open"] {
		animation: ${slideIn} ${duration}ms ${eases.cubic.out};
	}

	&[data-state="closed"] {
		animation: ${slideOut} ${duration}ms ${eases.cubic.in};
	}
`

const Wrapper = styled.div`
	position: relative;
	background: ${colors.blackMain};
	border: 0.5px solid ${colors.blackPure};
	z-index: 2;

	${fresponsive(css`
		min-width: 200px;
		width: fit-content;
		border-radius: 16px;
		box-shadow: 24px -28px 60px 8px rgba(0 0 0 / 17%),
			0 -1px 1px 0 rgba(255 255 255 / 11%);
		margin-top: 30px;
	`)};
`

const CutoutContainer = styled.div`
	position: absolute;
	transform: translateZ(0);
	border: 1px solid transparent;
	z-index: 3;

	${fresponsive(css`
		top: 0;
		transform: translateY(-17px);
		left: 16px;
		width: 40px;
		height: 18px;
	`)};
`

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	overflow: clip;

	${fresponsive(css`
		border-radius: 16px;
	`)};
`

const MainContent = styled.div`
	display: flex;
	border-bottom: 0.5px solid ${colors.blackLight};
`

const Columns = styled.div<{ numCols: number }>`
	display: flex;
	flex-direction: ${({ numCols }) => (numCols === 1 ? "column" : "row")};
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`

const DropdownBottom = styled.img`
	${fresponsive(css`
		width: 100%;
		padding: 16px;
		height: auto;
	`)}
`

const DropdownLink = styled(LinkText)<{ $open: boolean }>`
	background-color: ${({ $open }) => ($open ? "#ffffff20" : "#fff0")};

	svg {
		transition: transform 0.3s ${eases.quad.inOut};
		transform: ${({ $open }) => ($open ? "" : "rotate(180deg)")};
	}
`
