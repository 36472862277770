import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import { Icon, type IconName } from "./Icon"

export default function Subpage({
	onClick,
	style,
	onMouseEnter,
	onMouseLeave,
	title,
	previewText,
	iconName,
	link,
}: {
	onClick?: () => void
	style?: React.CSSProperties
	onMouseEnter?: () => void
	onMouseLeave?: () => void
	title: string
	previewText: string
	iconName: IconName
	link: string
}) {
	return (
		<Wrapper
			type="button"
			style={style}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<StyledIcon className="widget" name={iconName ?? "Email"} />
			<Right>
				<PageTitle>{title}</PageTitle>
				<PageDescription>{previewText}</PageDescription>
			</Right>
		</Wrapper>
	)
}

const Wrapper = styled(UniversalLink)`
	display: flex;
	align-items: center;
	border-bottom: 0.5px solid ${colors.blackLight};
	outline: none;
	transition: background-color 0.3s ease;

	/* Remove outline for non-keyboard :focus */
	&*:focus:not(:focus-visible) {
		outline: none;
	}

	/* Optional: Customize :focus-visible */
	&:focus-visible {
		outline-color: transparent;
	}

	${fresponsive(css`
		width: 250px;
		height: 80px;
		padding: 16px;
		gap: 16px;
	`)}

	${ftablet(css`
		border: 0.5px solid ${colors.blackLight};
		width: 100%;
	`)}

	${fmobile(css`
		border: 0.5px solid ${colors.blackLight};
		border-right: none;
		width: 167px;
		padding: 16px 8px 16px 16px;
		gap: 8px;

		&:last-of-type {
			border: 0.5px solid ${colors.blackLight};
		}
	`)}

  &:hover {
		background-color: ${colors.orangeGradient08};
		cursor: pointer;

		.widget {
			border: 0.5px solid ${colors.orange500};
		}

		svg {
			path {
				stroke: ${colors.orange500};
			}
		}
	}
`

const StyledIcon = styled(Icon)`
	transition: all 0.4s ease;
	outline: none;

	${fresponsive(css`
		padding: 8px;
		width: 40px;
		height: 40px;
		border-radius: 8px;
		border: 0.5px solid ${colors.blackLight};
	`)}

	${fmobile(css`
		padding: 8px;
	`)}
`

const Right = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	flex-shrink: 0;

	${fresponsive(css`
		gap: 8px;
		width: 140px;
	`)}

	${fmobile(css`
		flex-shrink: 1;
		width: 95px;
	`)}
`

const PageTitle = styled.h1`
	color: ${colors.gray300};
	${textStyles.body2S};
`

const PageDescription = styled.p`
	color: ${colors.gray700};
	${textStyles.body2Mini};

	${fresponsive(css`
		width: 140px;
	`)}

	${ftablet(css`
		display: none;
	`)}

	${fmobile(css`
		display: none;
	`)}
`
