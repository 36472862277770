import backgroundLinearStatic from "images/global/backgroundLinearStatic.webp"
import { createGlobalStyle, css } from "styled-components"
import backgroundStatic from "../images/global/backgroundStatic.webp"
import backgroundWhiteStatic from "../images/global/backgroundWhiteStatic.webp"

/**
 * these two images are the recommended backgrounds
 * any other backgrounds exist for legacy support only
 */
import backgroundDark from "images/global/backgroundDark.webp"
import backgroundWhite from "images/global/backgroundWhite.webp"

/**
 * place all your colors here! the format is:
 * [hex color, optional p3 color]
 *
 * if you provide a p3 color, it will be used where supported
 */
const rawColors = {
	pureWhite: ["#ffffff"],
	blackPure: ["#030303"],
	blackMain: ["#141414"],
	blackLight: ["#2B2B2B"],
	blueWhite: ["#F3FAFF"],
	orange500: ["#FF8D3A"],
	backgroundBlack: [`url(${backgroundDark})`],
	backgroundWhite: [`url(${backgroundWhite})`],
	orangeGradient: ["rgba(255, 141, 58, 0.36)"],
	orangeGradient08: ["rgba(255, 141, 58, 0.08)"],
	neonTeal: ["#3ECAF0"],
	teal: ["#077E9E"],
	navy: ["#243546"],
	gray300: ["#D7D7D7"],
	gray400: ["#A5A5A5"],
	gray500: ["#A5A5A5"], // used to be #808080, changed to match gray400 for contrast
	gray600: ["#636363"],
	gray700: ["#414141"],
	bgWhite: ["#F3FAFF"],
	productGreen1: ["#41DF1A"],
	productGreen2: ["#32B212"],
	productRed1: ["#FE5252"],
	productRed2: ["#D94B4B"],
} as const satisfies Record<string, [string, string] | [string]>

/** widen the type a bit for processing */
const colorEntries: [string, [string, string] | [string]][] =
	Object.entries(rawColors)

/**
 * sets the values of CSS variables globally
 * include this in layout
 */
export const ColorStyle = createGlobalStyle`
	:root {
		@supports (not (color: color(display-p3 0 0 0))) {
			${colorEntries.map(([key, [hex]]) => {
				return `--${key}: ${hex};`
			})}
		}

		@supports (color: color(display-p3 0 0 0)) {
			${colorEntries.map(([key, [hex, p3]]) => {
				return `--${key}: ${p3 ?? hex};`
			})}
		}
	}
`

/**
 * convert the raw colors to an object with the correct color for the current browser
 */
const CSSColors = Object.fromEntries(
	colorEntries.map(([key]) => {
		return [key, `var(--${key})`]
	}),
) as {
	[key in keyof typeof rawColors]: `var(--${key})`
}

/**
 * gsap can't animate variables, so we need to use the hex always
 */
const jsColors = Object.fromEntries(
	colorEntries.map(([key, [color]]) => {
		return [key, color]
	}),
) as {
	[key in keyof typeof rawColors]: (typeof rawColors)[key][0]
}

export const staticBackground = css`
	background-image: url(${backgroundStatic});
	background-size: 650px;
`

export const staticLinearBackground = css`
	background-image: url(${backgroundLinearStatic});
	background-repeat: repeat;
`

export const staticWhiteBackground = css`
	background-image: url(${backgroundWhiteStatic});
	background-size: 650px;
`

export default {
	...CSSColors,
	js: jsColors,
}

export const gradients = {
	gray: "linear-gradient(228deg, rgba(36, 36, 36, 0.25)-27%, rgba(138, 138, 138, 0.25)192.85%)",
} as const
