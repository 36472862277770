module.exports = [{
      plugin: require('../node_modules/.pnpm/@tmttn+gatsby-plugin-hubspot-tracking@5.2.0_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0___v6vmagttzo5i2zelc72lnbo6t4/node_modules/@tmttn/gatsby-plugin-hubspot-tracking/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"143321007","respectDNT":true,"productionOnly":true,"region":"eu1"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_64xf5ywh5eoz3mcrpofj5xuazm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rotate","short_name":"Rotate","start_url":"/","background_color":"#030303","theme_color":"#ffffff","display":"browser","icon":"./src/images/global/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f1c5c608ffbcf290ac1b7e69d79992a6"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react_hygwrhz76rz3w6ietj643lqvji/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PRLPXZKP","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"()=>(window.dataLayer=window.dataLayer||[],function(){window.dataLayer.push(arguments)}(\"consent\",\"default\",{ad_user_data:\"denied\",ad_personalization:\"denied\",ad_storage:\"denied\",analytics_storage:\"denied\",wait_for_update:500}),{platform:\"gatsby\"})"},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.26._i7b2ftvkadebfp5lszxozk5vkq/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__rdfanrp7bxeqyva26cwhgpnway/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
