import { fresponsive } from "library/fullyResponsive"
import { generateGradientBorder } from "library/generateGradientBorder"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { Icon, type IconName } from "./Icon"

export default function IconWidget({
	className = "",
	name,
}: {
	className?: string
	name: IconName
}) {
	return (
		<Wrapper className={className}>
			<Icon name={name} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	background: ${colors.backgroundBlack};
	display: grid;
	place-items: center;
	${fresponsive(css`
		width: 40px;
		height: 40px;
		border-radius: 8px;
	`)}

	&::before {
		content: "";
		position: absolute;
		border-radius: inherit;
		inset: 0;
		${generateGradientBorder(
			"linear-gradient(17deg, #252525 16.5%, #8B8B8B 218.45%)",
			1,
		)}
	}

	svg {
		width: 24px;
		height: 24px;
	}
`
